.modal-background {
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 600px;
    height: 550px;
    position: relative; 
    display: flex;
    flex-direction: column;
    align-items: center;
}

.close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

h2 {
    margin-bottom: 15px;
}

form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}


input[type="text"], input[type="email"] {
    width: 99%;
    height: 101%;
    padding-left: 3px;
    /* padding: 10px; */
    /* margin: 5px 0; */
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
}


/* label {
    display: flex;
    align-items: center;
    gap: 5px;
    width: 100%;
    justify-content: space-between;
} */

.search-results {
    height: 400px;
    width: 95%;
    overflow-y: auto;
    border: 1px solid #ccc;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 0px;
}


.search-results > div {
    /* Enable scrolling if content overflows */
    overflow-y: auto;
    cursor: pointer;
    padding: 5px;
    margin: 5px 0;
    transition: background 0.3s;
    &:hover {
        background-color: #6cdcf8;
    }
}

.input-container {
    margin-left: 10px; /* Add the desired space between the label and input field */
}


button.update {
    background-color: #3498db;
    color: white;
    margin-top: 15px;
    margin-right: 20px;
    padding: 5px 15px;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    width: 100%;
}
button.update:hover {
    background-color: #2980b9;
    color: white;
    margin-top: 15px;
    padding: 5px 15px;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    width: 100%;
}

button.cancel {
    background-color: black;
    color: white;
    margin-top: 15px;
    margin-right: 20px;
    padding: 5px 15px;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    width: 100%;
}
button.cancel:hover {
    background-color: #333;
    color: white;
    margin-top: 15px;
    padding: 5px 15px;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    width: 100%;
}

button.delete {
    background-color: red;
    color: white;
    margin-top: 15px;
    margin-right: 20px;
    padding: 5px 15px;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    width: 100%;
}
button.delete:hover {
    background-color: darkred;
    color: white;
    margin-top: 15px;
    padding: 5px 15px;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    width: 100%;
}

.phone-number-display {
    font-size: 18px;
    margin: 10px 0;
    color: #333;
    font-weight: 500;
}

.update-phone-button {
    background-color: purple;
    color: white;
    border: none;
    padding: 4px 8px; /* Adjust padding for reduced height */
    cursor: pointer;
    font-size: 12px;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.update-phone-button:hover {
    background-color: darkviolet;
}

.update-phone-button:focus {
    outline: none;
}

.update-phone-button:active {
    background-color: rebeccapurple;
}

