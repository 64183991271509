.App {
  text-align: center;
  background-color: rgb(0, 0, 0);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media screen and (min-width: 480px) {
  body {background-color: rgb(0, 0, 0)
  };
}

/* #282c34 */

.App-header {
  background-color: rgb(0, 0, 0);
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-container {
  text-align: center;
  background-color: rgb(0, 0, 0);
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom-file-input {
  display: inline-block;
  padding: 9px 20px;
  font-size: 13.5px;
  font-family: Roboto;
  margin-top: 5%;
  background-color: #b5b5c4; /* Default Material-UI light gray background */
  color: #2a2a2a; /* Default Material-UI black text */
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  line-height: 1.4;
}

.custom-file-input:hover {
  background-color: #b5b5c4; /* Adjusted hover background color to be slightly darker than the default */
}

.custom-file-input:active {
  background-color: #b5b5c4; /* Adjusted active background color to be slightly darker than hover */
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
}

.custom-file-input.disabled {
  background-color: #8a8a8a;
  color: #3a3a3a;
}

.MuiButton-contained.disabled {
  background-color: #8a8a8a !important;
  color: #3a3a3a !important;
}

/* Style when a value is selected */
.custom-select .react-dropdown-select-input.has-selection {
  margin-top: -15px; /* Move the selected text down */
  padding-top: 2px; /* Adjust this for fine-tuning */
}

/* Placeholder remains unaffected */
.custom-select .react-dropdown-select-placeholder {
  margin-top: 0;
  padding-top: 0;
}

html {
  -webkit-text-size-adjust: 100%; /* Prevents text resizing in Safari */
  -ms-text-size-adjust: 100%;     /* Prevents text resizing in IE */
  text-size-adjust: 100%;         /* Standard property */
}


