.rbc-time-view .rbc-time-gutter,
.rbc-time-view .rbc-time-content .rbc-timeslot-group {
    display: none;
}

.search-results-container {
    position: absolute;
    z-index: 1000; /* Ensures it appears on top */
    background-color: white;
    border: 1px solid #ccc; /* Thin border */
    width: auto; /* Adjust as needed */
    max-height: 295px; /* Height for 7 items, adjust based on your item height */
    overflow-y: auto; /* Adds scroll for overflow */
    overflow-X: auto; /* Adds scroll for overflow */
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Optional: Adds shadow for better visibility */
}

.search-result-item {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #eee; /* Separates items */
}

.search-result-item:hover {
    background-color: #cfe5ef; /* Optional: Highlight on hover */
}


  

.toolbar-label-container {
    position: relative;
}

.toolbar-label {
    position: absolute;
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%);
    font-size: 22px; 
    white-space: nowrap;
}

/* Example of a media query for smaller screens */
@media screen and (max-width: 600px) {
    .toolbar-label {
        font-size: 18px;
    }
}

.fixed-progress-bar {
    position: fixed;  /* This will keep it fixed relative to the window */
    top: 0.5%;        /* Adjust these values as needed to position the progress bar */
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;   /* Ensure it's above other content */
}

.disabled-modal-content {
    pointer-events: none; /* Disables all mouse events on the modal content */
    opacity: 0.5; /* Optional: lowers the opacity to visually indicate it is disabled */
}

.rbc-month-row {
    font-size: 13px !important;
    /* font-weight: 700 !important; */
    min-height: 150px !important;
  }

.rbc-date-cell {
    /* font-size: 15px !important; */
    color: rgb(249, 2, 2) !important;
    /* font-weight: 700 !important; */
    /* margin-bottom: 10px; */
}

.rbc-date-cell.rbc-now {
    font-weight: bold !important;
  }

  .rbc-row-content {
    display: all !important;
    /* overflow-y: auto; */
}

.rbc-month-view .rbc-day-bg + div {
    overflow-y: auto;
    max-height: 100px; /* Adjust based on your preference */
}

.rbc-show-more {
    display: none !important;
  }

  /* .rbc-event-label {
    height: 5px;
    padding: 2px 5px;
    font-size: 0.4em;
  } */

  /* .rbc-row-content .rbc-row:nth-child(n + 4) {
    display: none !important;
} */

html {
    -webkit-text-size-adjust: 100%; /* Prevents text resizing in Safari */
    -ms-text-size-adjust: 100%;     /* Prevents text resizing in IE */
    text-size-adjust: 100%;         /* Standard property */
  }

  .rbc-calendar {
    height: calc(100vh - 150px); /* Subtract space for headers or other elements */
    overflow-y: auto; /* Enable scrolling within the calendar if needed */
    box-sizing: border-box; /* Ensure padding and borders don't affect dimensions */
  }
  

  .rbc-toolbar {
    position: sticky !important; /* Force sticky behavior */
    top: -1;
    z-index: 1000;
    background-color: white;
  }
  


  
  
  
  


